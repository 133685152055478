import * as React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import i18n from "../i18next";
import * as styles from "../styles/privacy.module.scss"





const Privacy = () => {
    const isGerman = i18n.language === "de"? true:false;



    return(
    <Layout type = "privacy" isDark={false} isMobile={false} key={i18n.language}>
    <div className="dark">
    <div className={styles.privacy}>

        {isGerman?
        <>
         <h1>Datenschutz</h1>
                <h4>1. Datenschutz auf einen Blick</h4>
                <h5>Allgemeine Hinweise</h5>
                <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung</p>
                <h5>Datenerfassung auf dieser Website</h5>
                <h6>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h6>
                <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
                <h6>Wie erfassen wir Ihre Daten?</h6>
                <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeitdes Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
                <h6>Wofür nutzen wir Ihre Daten?</h6>
                <p>Die Informationen, die Sie uns durch das Ausfüllen unseres Kontaktformulars zur Verfügung stellen, werden ausschließlich dazu verwendet, mit Ihnen während des gesamten Prozesses Ihrer Anfrage in Kontakt zu bleiben. Wir sind bestrebt, die Sicherheit Ihrer Daten zu gewährleisten. Um einen unbefugten Zugriff oder eine unbefugte Offenlegung zu verhindern, haben wir geeignete physische, elektronische und verwaltungstechnische Verfahren eingerichtet, um die von uns online erfassten Daten zu schützen und zu sichern.</p>
                <p>Automatisch gesammelte personenbezogene Daten werden nur zur korrekten Darstellung des Inhalts unserer Website, zur Optimierung des Inhalts unserer Website und für statistische Analysen verwendet. Diese Daten sind anonymisiert und lassen keine Rückschlüsse auf einzelne Nutzer zu.</p>
                <h6>Welche Rechte haben Sie bezüglich Ihrer Daten?</h6>
                <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
                <h4>2. Hosting</h4>
                <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
                <h5>IONOS</h5>
                <p>Anbieter ist die IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (nachfolgend IONOS). Wenn Sie unsere Website besuchen, erfasst IONOS verschiedene Logfiles inklusive Ihrer IP-Adressen. Details entnehmen Sie der Datenschutzerklärung von IONOS: https://www.ionos.de/terms-gtc/terms-privacy.</p>
                <p>Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
                <h4>3. Allgemeine Hinweise und Pflichtinformationen</h4>
                <h5>Datenschutz</h5>
                <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                <h5>Hinweis zur verantwortlichen Stelle</h5>
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                <p>Vanilla Noir</p>
                <p>35 Medallion House</p>
                <p>Joseph Terry Grove</p>
                <p>Telefon: +44 7747 206413</p>
                <p>E-Mail: info@vanilla-noir.com</p>
                <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>
                <h5>Speicherdauer</h5>
                <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
                <h5>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h5>
                <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.</p>
                <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
                <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                <h5>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h5>
                <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO)</p>
                <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
                <h5>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h5>
                <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
                <h5>Recht auf Datenübertragbarkeit</h5>
                <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                <h5>Auskunft, Berichtigung und Löschung</h5>
                <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
                <h5>Recht auf Einschränkung der Verarbeitung</h5>
                <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
                <ul>
                    <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                    <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                    <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                    <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                </ul>
                <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
                <h5>SSL- bzw. TLS-Verschlüsselung</h5>
                <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                <h4>4. Datenerfassung auf dieser Website</h4>
                <h5>Kontaktformular</h5>
                <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne IhreEinwilligung weiter.</p>
                <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
                <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
                <h5>Anfrage per E-Mail, Telefon oder Telefax</h5>
                <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
                <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
        </>
        :
        <>
         <h1>Data Protection</h1>
            <h4>1. Data Protection at a Glance</h4>
            <h5>General Information</h5>
            <p>The following information provides a simple overview of what happens to your personal data when you visit this website. Personal data are all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our privacy policy below this text.</p>
            <h5>Data Collection on this Website</h5>
            <h6>Who is responsible for data collection on this website?</h6>
            <p>Data processing on this website is carried out by the website operator. You can find their contact details in the "Responsible Party" section of this privacy policy.</p>
            <h6>How do we collect your data?</h6>
            <p>Your data is collected when you provide it to us. This may include data you enter into a contact form.</p>
            <p>Other data is automatically collected or recorded by our IT systems when you visit the website. This data is primarily technical data (e.g. internet browser, operating system, or time of page access). This data is collected automatically as soon as you enter our website.</p>
            <h6>How do we use your data?</h6>
            <p>The information you provide us with by filling out our contact form will be solely used to keep in contact with you throughout the process of your enquiry. We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            <p>Automatically collected personal information is used only to deliver the content of our website correctly, to optimise the content of our website, and for statistical analyses. This data is anonymised and cannot be used to make any conclusions about individual users.</p>
            <h6>Your rights regarding your data</h6>
            <p>You have the right to receive information about the origin, recipients, and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. Furthermore, you have the right to request the restriction of the processing of your personal data under certain circumstances. You also have the right to lodge a complaint with the competent supervisory authority. For this purpose and for further questions on the subject of data protection, you can contact us at any time.</p>
            <h4>2. Hosting</h4>
            <p>We host the content of our website with the following provider:</p>
            <h5>IONOS</h5>
            <p>The provider is IONOS SE, Elgendorfer Str. 57, 56410 Montabaur (hereinafter referred to as IONOS). When you visit our website, IONOS collects various log files, including your IP addresses. For details, please refer to IONOS's privacy policy: https://www.ionos.de/terms-gtc/terms-privacy.</p>
            <p>The use of IONOS is based on Art. 6(1)(f) GDPR. We have a legitimate interest in presenting our website as reliably as possible. If consent has been requested, the processing will be carried out exclusively based on Art. 6(1)(a) GDPR and § 25(1) TTDSG, to the extent that the consent includes the storage of cookies or access to information on the user's device (e.g. device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.</p>
            <h4>3. General Information and Mandatory Information</h4>
            <h5>Data Protection</h5>
            <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.</p>
            <p>When you use this website, various personal data is collected. Personal data is data that can be used to identify you personally. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done. Please note that data transmission over the Internet (e.g. communication by email) may have security vulnerabilities. Complete protection of data against access by third parties is not possible.</p>
            <h5>Notice concerning the party responsible for this website</h5>
            <p>The party responsible for processing data on this website is:</p>
            <p>Vanilla Noir</p>
            <p>35 Medallion House</p>
            <p>Joseph Terry Grove</p>
            <p>Telefon: +44 7747 206413</p>
            <p>E-Mail: info@vanilla-noir.com</p>
            <p>The responsible party is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (e.g. names, email addresses, etc.).</p>
            <h5>Storage period:</h5>
            <p>Unless a more specific storage period is mentioned in this privacy policy, your personal data will remain with us until the purpose for data processing ceases. If you submit a legitimate request for deletion or revoke your consent for data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial retention periods). In the latter case, deletion will occur after the expiration of these reasons.</p>
            <h5>General information on the legal basis for data processing on this website:</h5>
            <p>If you have given your consent to data processing, we process your personal data based on Art. 6(1)(a) GDPR or Art. 9(2)(a) GDPR if special categories of data are processed in accordance with Art. 9(1) GDPR. In the event of explicit consent to the transfer of personal data to third countries, data processing is also based on Art. 49(1)(a) GDPR. If you have consented to the storage of cookies or access to information on your terminal device (e.g., via device fingerprinting), data processing also takes place based on § 25(1) TTDSG. Consent can be revoked at any time. If your data is necessary for the performance of a contract or for pre-contractual measures, we process your data based on Art. 6(1)(b) GDPR. Furthermore, we process your data if it is necessary for compliance with a legal obligation based on Art. 6(1)(c) GDPR. Data processing may also occur based on our legitimate interests according to Art. 6(1)(f) GDPR. The relevant legal bases applicable in each specific case are provided in the following paragraphs of this privacy policy.</p>
            <h5>Withdrawal of your consent to data processing:</h5>
            <p>Many data processing operations are only possible with your explicit consent. You can revoke consent that has already been given at any time. The lawfulness of data processing prior to the revocation remains unaffected by the withdrawal.</p>
            <h5>Right to object to data collection in special cases and to direct marketing (Art. 21 GDPR):</h5>
            <p>If data processing is based on Art. 6(1)(e) or (f) GDPR, you have the right to object, on grounds relating to your particular situation, to the processing of your personal data at any time, including profiling based on those provisions. The respective legal basis for data processing can be found in this privacy policy. If you object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing that override your interests, rights, and freedoms or the processing serves to assert, exercise, or defend legal claims (objection pursuant to Art. 21(1) GDPR).</p>
            <p>If your personal data is processed for direct marketing purposes, you have the right to object at any time to the processing of your personal data for such marketing, including profiling, to the extent that it is related to such direct marketing. If you object, your personal data will no longer be used for direct marketing purposes (objection pursuant to Art. 21(2) GDPR).</p>
            <h5>Right to lodge a complaint with the supervisory authority:</h5>
            <p>In the event of violations of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the member state of their habitual residence, place of work, or the place of the alleged infringement. This right to lodge a complaint is without prejudice to any other administrative or judicial remedies.</p>
            <h5>Right to data portability:</h5>
            <p>You have the right to receive the data that we process automatically based on your consent or in fulfillment of a contract, in a common, machine-readable format, and to have it handed over to yourself or to a third party. If you request the direct transfer of the data to another controller, this will only be done to the extent that it is technically feasible.</p>
            <h5>Information, Correction, and Deletion</h5>
            <p>You have the right, within the scope of applicable legal provisions, to obtain free information about your stored personal data, their origin and recipients, the purpose of data processing, and, if necessary, the right to rectify or erase this data. For this purpose, as well as for further questions regarding personal data, you can contact us at any time.</p>
            <h5>Right to Restrict Processing</h5>
            <p>You have the right to request the restriction of the processing of your personal data. You can contact us at any time for this purpose. The right to restrict processing exists in the following cases:</p>
            <ul>
                <li>If you dispute the accuracy of your personal data stored with us, we usually need time to verify this. During the verification period, you have the right to request the restriction of the processing of your personal data.</li>
                <li>If the processing of your personal data has been or is being carried out unlawfully, you can request the restriction of data processing instead of erasure.</li>
                <li>If we no longer need your personal data but you require it for the exercise, defense, or assertion of legal claims, you have the right to request the restriction of the processing of your personal data instead of deletion.</li>
                <li>If you have objected to the processing of your personal data in accordance with Art. 21(1) of the General Data Protection Regulation (GDPR), a balance must be struck between your interests and ours. As long as it is not yet clear whose interests prevail, you have the right to request the restriction of the processing of your personal data.</li>
                <li>If you have restricted the processing of your personal data, aside from storage, these data may only be processed with your consent or for the assertion, exercise, or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a member state.</li>
            </ul>
            <h5>SSL or TLS Encryption</h5>
            <p>For security reasons and to protect the transmission of confidential content, such as orders or inquiries that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.</p>
            <p>When SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.</p>
            <h4>4. Data Collection on this Website</h4>
            <h5>Contact Form</h5>
            <p>If you send us inquiries via the contact form, your information from the inquiry form, including the contact details you provided there, will be stored by us for the purpose of processing the inquiry and in case of follow-up questions. We will not share this data without your consent.</p>
            <p>The processing of this data is based on Art. 6(1)(b) of the General Data Protection Regulation (GDPR) if your inquiry is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of inquiries directed to us (Art. 6(1)(f) GDPR) or on your consent (Art. 6(1)(a) GDPR) if it has been requested; consent can be revoked at any time.</p>
            <p>The data you enter in the contact form will remain with us until you request deletion, revoke your consent for storage, or the purpose for data storage no longer applies (e.g., after completing the processing of your inquiry). Mandatory legal provisions, especially retention periods, remain unaffected.</p>
            <h5>Inquiry by Email, Telephone, or Fax</h5>
            <p>If you contact us by email, telephone, or fax, your inquiry, including all resulting personal data (name, inquiry), will be stored and processed by us for the purpose of processing your request. We will not share this data without your consent.</p>
            <p>The processing of this data is based on Art. 6(1)(b) GDPR if your inquiry is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of inquiries directed to us (Art. 6(1)(f) GDPR) or on your consent (Art. 6(1)(a) GDPR) if it has been requested; consent can be revoked at any time.</p>
            <p>The data you send us via contact inquiries will remain with us until you request deletion, revoke your consent for storage, or the purpose for data storage no longer applies (e.g., after completing the processing of your request). Mandatory legal provisions, especially statutory retention periods, remain unaffected.</p>
        </>

        }

   
           
     </div>           
    </div>
  </Layout>
    )

}

export default Privacy

export const Head = () => (
    <Seo title="Vanilla Noir | Privacy" titleDE="Vanilla Noir | Datenschutz" description="Our privacy policy makes sure your personal information is protexted and never gets misused." descriptionDE="Unsere Datenschutzerklärung stellt sicher, dass Ihre persönlichen Informationen immer geschützt sind." />
    )